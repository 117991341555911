<template>
  <div>
    <div class="flex flex-col-reverse lg:flex-row items-start lg:items-center justify-between flex-wrap">
      <app-locale-selector
        v-if="withLocaleSelector"
        class="w-28"
        :options="dynamicLocalesOptions"
        :initial-value="locale"
        :hide-errors="true"
        @changed="handleSelectChange"
      />

      <point-of-interest-actions
        v-if="withActions"
        :resource="resource"
        :show-labels="true"
        class="mb-4"
        @claimed="handleClaim"
        @import-sync-disabled="handleImportSyncDisable"
        @claim-disputed="handleClaimDispute"
        @destroyed="destroyCallback"
        @advice-updated="handleAdviceUpdated"
      />
    </div>

    <!-- Incomplete translation notice -->
    <app-alert
      v-if="withLocaleSelector && unavailableCurrentLocale"
      design="warning"
      :title="t('ttmt.points_of_interest.pages.show.not_current_locale.missing_translations')"
      class="my-4"
    >
      <p>
        {{ t('ttmt.points_of_interest.pages.show.not_current_locale.incomplete_translation') }}
      </p>

      <p>
        {{ t('ttmt.points_of_interest.pages.show.not_current_locale.change_locale') }}
      </p>
    </app-alert>

    <!-- Claim notice -->
    <claim-notice
      v-if="showClaimNotice"
      :resource="claim"
    />

    <!-- Claim dispute notice -->
    <app-alert
      v-if="claimDispute && !resource.attributes.owned_by_auth_user"
      design="info"
      :title="t('ttmt.points_of_interest.pages.show.claim_dispute_pending.notice')"
      class="mb-4"
    >
      <p>
        {{ t('ttmt.points_of_interest.pages.show.claim_dispute_pending.title') }}
      </p>

      <p>
        {{ t(
          'ttmt.points_of_interest.pages.show.claim_dispute_pending.details',
          { message: claimDispute.attributes.contestant_message }
        ) }}
      </p>
    </app-alert>

    <!-- Opening hours expired -->
    <app-alert
      v-if="resource.attributes.held_by_auth_user
        && isExpired
        && isExpirable"
      design="danger"
      :title="t('ttmt.points_of_interest.pages.show.deletion.title')"
      class="mb-4"
    >
      <p>
        {{ t('ttmt.points_of_interest.pages.show.opening_hours.is_deleting', daysBeforeDeleting ) }}
      </p>

      <p class="font-medium">
        {{ t('ttmt.points_of_interest.pages.show.deletion.causes.event') }}
      </p>
    </app-alert>
  </div>

  <!-- TITLE / CATEGORY / ADVICE -->
  <div
    class="flex space-x-3 w-full"
  >
    <div class="flex flex-col justify-around w-1/2">
      <div class="flex flex-wrap justify-between items-center">
        <p
          class="font-bold text-2xl flex items-center"
        >
          <span>
            {{ l10nTitle }}
          </span>

          <span
            v-if="resource.attributes.certified"
            class="ml-2"
          >
            <app-icon
              library="coolicon"
              name="circle_check_outline"
              size="text-2xl"
            />
          </span>
        </p>

        <p
          v-if="resource.attributes?.fallback_locale"
          class="mb-2 text-sm font-semibold"
        >
          {{
            getAttributeTranslation(
              resource.attributes.title,
              resource.attributes.fallback_locale
            )
          }}
        </p>
      </div>
      <div class="flex justify-between items-center">
        <div class="flex flex-wrap items-center font-bold text-xl w-1/2">
          <!-- Category -->
          <div
            v-if="resource?.relationships?.category?.attributes?.label"
            class="flex items-center mr-6"
          >
            <app-icon
              library="coolicon"
              name="tag-outline"
              class="mr-2"
            />

            <span>
              {{ getAttributeTranslation(resource?.relationships?.category?.attributes?.label) }}
            </span>
          </div>

          <!-- Stars -->
          <div
            v-if="resource.attributes.stars > 0"
            class="mr-6"
          >
            <span
              v-for="i in resource.attributes.stars"
              :key="i"
            >
              <font-awesome-icon
                icon="star"
              />
            </span>
          </div>

          <!-- Tags -->
          <div
            v-if="tags"
            class="mr-6"
          >
            <font-awesome-icon
              icon="hashtag"
            />
            {{ tags }}
          </div>
        </div>
      </div>
    </div>

    <advice-card
      v-show="showAdvice"
      class="w-1/2"
      :advice="resource.relationships?.advice"
      :locale="selectedLocale"
    />
  </div>

  <div class="grid gap-4 grid-cols-1 lg:grid-cols-2">
    <div class="flex">
      <!-- Ownership -->
      <p
        v-if="resource.attributes.owned_by_auth_user"
        class="font-medium mb-3.5"
      >
        {{ t('ttmt.points_of_interest.pages.show.belongs_to_you') }}
      </p>

      <span
        v-if="resource.attributes.owned_by_auth_user && displayExcursionsCounter"
        class="mx-2"
      >
        &bull;
      </span>

      <!-- Excursions counter -->
      <p
        v-if="displayExcursionsCounter"
        class="font-medium mb-3.5"
      >
        {{ t('ttmt.points_of_interest.pages.show.excursions_counter', { counter: resource.attributes.excursions_counter }) }}
      </p>
    </div>

    <div class="text-right mb-2">
      <p
        v-if="isSynchronized"
        class="font-bold text-base"
      >
        {{ t('ttmt.points_of_interest.pages.show.automatic_update') }}
      </p>
    </div>
  </div>

  <div class="grid gap-4 grid-cols-1 lg:grid-cols-2">
    <div>
      <app-card-item class="p-4 h-fit">
        <!-- Description -->
        <div>
          <p class="font-bold text-lg mb-1">
            {{ capitalize(t('validation.attributes.description')) }}
          </p>

          <p>
            {{ l10nDescription }}
          </p>
        </div>

        <!-- Address -->
        <div>
          <p class="font-bold text-lg mb-1 mt-6">
            {{ capitalize(t('validation.attributes.address')) }}
          </p>

          <app-data-grid-address
            :address="resource?.relationships?.address"
            :locale="selectedLocale"
          />

          <!-- Distance -->
          <div
            v-if="resource.attributes.distance"
            class="mt-2 text-sm"
          >
            {{ distanceToDisplay }}
          </div>
        </div>

        <!-- Opening Hours -->
        <div>
          <p class="font-bold text-lg mb-1 mt-6">
            {{ capitalize(t('validation.attributes.opening_hours')) }}
          </p>

          <div
            v-if="resource?.attributes?.opening_hours.length > 0"
          >
            <app-button
              font-size="sm"
              feature="show"
              class="my-1 md:mx-1"
              @click="handleOpeningHoursModal"
            />

            <!-- Modal -->
            <app-overlay
              :show="openingHoursModalDisplayed"
              @clicked="handleOpeningHoursCancelModal"
            >
              <app-modal
                text-align-classes="text-left"
                @closed="handleOpeningHoursCancelModal"
              >
                <point-of-interest-opening-hours
                  :is-expired="isExpired"
                  :opening-hours="resource?.attributes?.opening_hours"
                  :locale="selectedLocale"
                />
              </app-modal>
            </app-overlay>
          </div>

          <p
            v-else
            class="italic"
          >
            {{ t('ttmt.common.not_specified') }}
          </p>
        </div>

        <!-- Contact -->
        <div>
          <p class="font-bold text-lg mb-1 mt-6">
            {{ capitalize(t('validation.attributes.phone')) }}
          </p>

          <app-data-grid-link
            type="phone"
            :link="resource?.attributes?.contact?.phone"
          />
        </div>

        <div>
          <p class="font-bold text-lg mb-1 mt-6">
            {{ capitalize(t('validation.attributes.email')) }}
          </p>

          <app-data-grid-link
            type="email"
            :link="resource?.attributes?.contact?.email"
          />
        </div>

        <div>
          <p class="font-bold text-lg mb-1 mt-6">
            {{ capitalize(t('validation.attributes.website')) }}
          </p>

          <app-data-grid-link
            :link="resource?.attributes?.contact?.website"
          />

          <div>
            <p
              v-if="hasDTRequiredFields"
              class="text-sm mb-1 mt-6"
            >
              {{
                t('ttmt.points_of_interest.pages.show.dt_required_datatourisme_fields',
                  {
                    import_source: resource.attributes.import_source,
                    dt_created_by: resource.attributes.dt_created_by,
                    dt_last_update: formatDateShort(resource.attributes.dt_last_update)
                  }
                )
              }}
            </p>
          </div>
        </div>
      </app-card-item>
    </div>

    <!-- Map -->
    <div>
      <app-embed-map
        v-if="hasCoords || resource.relationships.address?.attributes?.is_geocoding"
        :latitude="resource.relationships.address.attributes.latitude"
        :longitude="resource.relationships.address.attributes.longitude"
        :title="l10nTitle"
        :is-geocoding="resource.relationships.address.attributes.is_geocoding"
        height="h-96 lg:h-120"
      />
    </div>
  </div>

  <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 my-4">
    <!-- Gallery -->
    <div v-if="gallery.length > 0">
      <app-section-title
        :title="t('ttmt.points_of_interest.pages.show.sections.gallery')"
        class="mb-3.5"
      />

      <app-card-item
        class="p-4"
      >
        <images-gallery
          :images="gallery"
        />
      </app-card-item>
    </div>

    <!-- Logo -->
    <div v-if="logo">
      <app-section-title
        :title="t('ttmt.points_of_interest.pages.show.sections.logo')"
        class="mb-3.5"
      />

      <app-card-item
        class="p-4"
      >
        <img
          class="w-52 mx-auto"
          :src="logoUrl"
          alt="Point of interest logo"
        >
      </app-card-item>
    </div>

    <!-- Audio files  -->
    <div
      v-if="audios.length > 0 || resource.attributes.is_refreshing_audio"
    >
      <div>
        <app-section-title
          :title="t('ttmt.points_of_interest.pages.show.sections.audios')"
          class="mb-3.5"
        />

        <div
          v-for="(audio, i) in audios"
          :key="i"
        >
          <!-- Hide audio if it's a TTS and the audio is waiting for refresh -->
          <app-card-item
            v-if="audio.attributes.source !== 'textToSpeech' || !resource.attributes.is_refreshing_audio"
            class="p-3 mb-5"
          >
            <div
              class="mx-auto w-fit text-center"
            >
              <p
                v-if="audio.attributes.name"
                class="font-bold mb-2"
              >
                {{ audio.attributes.name }}
              </p>

              <app-audio-player
                :src="audio.attributes.url"
              />

              <div class="mt-2">
                <p
                  v-if="audio.attributes.locale"
                  class="text-xs"
                >
                  {{ t(`ttmt.locales.${audio.attributes.locale}`) }}
                </p>

                <app-card-item
                  v-if="audio.attributes.source === 'textToSpeech'"
                  class="text-xs w-fit px-3 py-1 font-bold mx-auto mt-1"
                  background-classes="bg-gray-300"
                >
                  {{ t('ttmt.media.audio.generated_audio') }}
                </app-card-item>
              </div>
            </div>
          </app-card-item>
        </div>

        <!-- Refreshing audio placeholder -->
        <app-card-item
          v-if="resource.attributes.is_refreshing_audio"
          class="p-3 mb-5 flex items-center justify-center flex-col"
          background-classes="bg-gray-200 text-center"
        >
          <app-icon
            library="fontawesome"
            name="volume-up"
            size="2x"
            class="mb-2"
          />

          <p class="font-bold text-sm mb-1">
            {{ t('ttmt.points_of_interest.pages.show.is_refreshing_audio_1') }}
          </p>

          <p class="font-bold text-sm">
            {{ t('ttmt.points_of_interest.pages.show.is_refreshing_audio_2') }}
          </p>
        </app-card-item>
      </div>
    </div>

    <!-- Broadcast page  -->
    <div
      v-if="canEditBroadcastPage"
    >
      <app-section-title
        :title="t('ttmt.broadcast.ui.titles.broadcast_page')"
        class="mb-3.5"
      />

      <app-card-item>
        <div
          class="px-4 pt-4"
        >
          <app-status-badge
            :design="broadcastPageStatus.state"
            :text="broadcastPageStatus.text"
          />
        </div>

        <CopyToClipboard
          :url="resource.relationships?.broadcast_page?.attributes?.url"
        />
      </app-card-item>
    </div>

    <!-- Video files  -->
    <div
      v-if="videos.length > 0"
    >
      <app-section-title
        :title="t('ttmt.points_of_interest.pages.show.sections.videos')"
        class="mb-3.5"
      />

      <app-video-player
        v-for="(video, i) in videos"
        :key="i"
        :src="video.attributes.url"
        class="my-4 max-w-screen-sm"
      />
    </div>
  </div>

  <!-- Reservations -->
  <div
    v-if="withReservations && store.state.auth.user.authorizations.reservations.viewAny"
    class="my-4"
  >
    <div class="mb-3.5 flex items-start md:items-center flex-col md:flex-row max-w-sm md:max-w-full">
      <app-section-title
        :title="t('ttmt.traveller_folders.pages.show.sections.reservations')"
        class="mb-3 mr-0 md:mb-0 md:mr-5"
      />

      <app-button
        v-if="withActions && store.state.auth.user.authorizations.reservations.create"
        feature="add"
        font-size="xs"
        @click="router.push({
          name: 'create-reservations',
          query: { point_of_interest_id: resource.id },
        })"
      />
    </div>

    <reservation-table
      v-if="resource"
      mode="poi"
      :filter-id="resource.id"
    />
  </div>

  <!-- Employees -->
  <div
    v-if="withEmployees && resource.attributes.owned_by_auth_user"
    class="my-4"
  >
    <employee-table
      mode="institutions"
      :poi="resource.id"
    />
  </div>
</template>

<script setup>
import { capitalize, isNil } from 'lodash'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import useDatetime from '@shared/hooks/datetime'
import usePointOfInterest from '@shared/hooks/resources/pointOfInterest'
import useTag from '@shared/hooks/resources/tag'
import useLocale from '@shared/hooks/locale'

import AdviceCard from '@extranet/components/resources/point_of_interest/advices/AdviceCard.vue'
import AppAudioPlayer from '@shared/components/ui/AppAudioPlayer.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppDataGridAddress from '@extranet/components/ui/data_grid/AppDataGridAddress.vue'
import AppDataGridLink from '@extranet/components/ui/data_grid/AppDataGridLink.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'
import AppEmbedMap from '@shared/components/ui/map/AppEmbedMap.vue'
import AppLocaleSelector from '@shared/components/ui/AppLocaleSelector.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import AppVideoPlayer from '@shared/components/ui/AppVideoPlayer.vue'
import AppAlert from '@shared/components/ui/AppAlert.vue'
import AppStatusBadge from '@shared/components/ui/AppStatusBadge.vue'
import ClaimNotice from '@extranet/components/features/claim/ClaimNotice.vue'
import CopyToClipboard from '@extranet/components/resources/broadcast_page/CopyToClipboard.vue'
import EmployeeTable from '@extranet/components/resources/employee/EmployeeTable.vue'
import ImagesGallery from '@extranet/components/resources/image/ImagesGallery.vue'
import PointOfInterestOpeningHours from '@shared/components/resources/point_of_interest/PointOfInterestOpeningHours.vue'
import PointOfInterestActions from '@extranet/components/resources/point_of_interest/PointOfInterestActions.vue'
import ReservationTable from '@extranet/components/resources/reservation/ReservationTable.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Function called on delete handling
  destroyCallback: {
    type: Function,
    default: () => ({}),
  },
  // Display actions or not
  withActions: {
    type: Boolean,
    default: true,
  },
  // Display locale selector or not
  withLocaleSelector: {
    type: Boolean,
    default: true,
  },
  // Display employees or not
  withEmployees: {
    type: Boolean,
    default: true,
  },
  // Display reservations or not
  withReservations: {
    type: Boolean,
    default: true,
  },
})

const emits = defineEmits([
  'claimed',
  'claimDisputed',
  'importSyncDisabled',
  'adviceUpdated',
])

const router = useRouter()
const store = useStore()
const { t, locale } = useI18n()
const {
  gallery,
  audios,
  videos,
  hasCoords,
  logo,
  getLogoUrl,
  displayExcursionsCounter,
  hasDTRequiredFields,
  isExpired,
  isExpirable,
  daysBeforeDeleting,
  distanceToDisplay,
} = usePointOfInterest(props)
const {
  getAttributeTranslation,
  dynamicLocalesOptions,
} = useLocale()
const { formatDateShort } = useDatetime()

// ---------- POI ----------

const isSynchronized = computed(() => (
  props.resource?.attributes?.source === 'import'
  && props.resource?.attributes?.import_source === 'DataTourisme'
  && props.resource?.attributes?.import_synchronizing
))

const l10nTitle = computed(() => {
  let title = props.resource.attributes.title[selectedLocale?.value]

  if (!title) {
    title = t('ttmt.common.not_specified')
  }

  return title
})

const l10nDescription = computed(() => {
  let description = props.resource.attributes.description[selectedLocale?.value]

  if (!description) {
    description = t('ttmt.common.not_specified')
  }

  return description
})

const unavailableCurrentLocale = computed(() => (
  isNil(props.resource.attributes.title[selectedLocale?.value])
  || isNil(props.resource.attributes.description[selectedLocale?.value])
  || isNil(props.resource.relationships?.address?.attributes?.line1?.[selectedLocale?.value])
  || isNil(props.resource.relationships?.address?.attributes?.city?.[selectedLocale?.value])
))

// ---------- LOCALE ----------

const selectedLocale = ref(locale.value)

function handleSelectChange(value) {
  selectedLocale.value = value
}

// ---------- CLAIM ----------

function handleClaim() {
  emits('claimed')
}

// ---------- UPDATE ----------

function handleImportSyncDisable() {
  emits('importSyncDisabled')
}

// ---------- TAGS ----------

const { displayTags } = useTag(props)

const tags = computed(() => (
  displayTags()
))

// ---------- LOGO ----------

const logoUrl = computed(() => (
  getLogoUrl('sm')
))

// ---------- CLAIM ----------

const claim = computed(() => (
  props.resource.relationships?.claim
))

const showClaimNotice = computed(() => (
  claim.value
    && claim.value.attributes.state !== 'validated'
))

// ---------- CLAIM DISPUTE ----------

function handleClaimDispute() {
  emits('claimDisputed')
}

const claimDispute = computed(() => (
  props.resource.relationships?.claim_dispute
))

// ---------- OPENING HOURS ----------

const openingHoursModalDisplayed = ref(false)

function handleOpeningHoursCancelModal() {
  openingHoursModalDisplayed.value = false
}

function handleOpeningHoursModal() {
  openingHoursModalDisplayed.value = true
}

// ---------- ADVICE ----------

const showAdvice = computed(() => (
  props.resource?.relationships?.advice !== null
    && props.resource?.relationships?.advice?.authorizations?.view
))

function handleAdviceUpdated($event) {
  emits('adviceUpdated', $event)
}

// ---------- BROADCAST PAGE ----------

const canEditBroadcastPage = computed(() => (
  props.resource.attributes?.owned_by_auth_user
    && props.resource.relationships?.broadcast_page !== null
))

const broadcastPageStatus = computed(() => {
  if (props.resource.relationships?.broadcast_page?.attributes?.published) {
    return {
      text: t('ttmt.broadcast.ui.published'),
      state: 'success',
    }
  }

  return {
    text: t('ttmt.broadcast.ui.not_published'),
    state: 'danger',
  }
})
</script>
