<template>
  <div>
    <app-alert
      design="info"
      :title="t('ttmt.advice.point_of_interest.card_title')"
      class="rounded-md text-sm font-bold"
    >
      <p class="font-normal">
        {{ content }}
      </p>
    </app-alert>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import AppAlert from '@shared/components/ui/AppAlert.vue'

const {
  t,
  locale: appLocale,
} = useI18n()

const props = defineProps({
  // Advice object prop
  advice: {
    type: Object,
    default: null,
  },
  // Locale used for translation
  locale: {
    type: String,
    default: 'en',
  },
})

const content = computed(() => {
  if (props?.advice) {
    if (Object.keys(props?.advice?.attributes?.content).includes(localeToUse?.value)) {
      return props?.advice?.attributes?.content?.[localeToUse.value]
    }

    return Object.entries(props.advice?.attributes?.content)[0][1]
  }

  return null
})

// ---------- LOCALE ----------

const localeToUse = computed(() => (
  props.locale ?? appLocale.value ?? Object.entries(props?.advice?.attributes?.content)[0]
))
</script>
