<template>
  <app-button
    v-if="broadcastPageExist && hasPermissionToUpdate"
    font-size="sm"
    :label="t('ttmt.broadcast.ui.buttons.broadcast_page')"
    feature="add"
    @click="handleEditRoute"
  />

  <app-button
    v-if="!broadcastPageExist && hasPermissionToCreate"
    font-size="sm"
    :label="t('ttmt.broadcast.ui.buttons.create_broadcast_page')"
    feature="add"
    @click="handleCreateRoute"
  />
</template>

<script setup>
import {
  computed,
} from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import AppButton from '@shared/components/ui/AppButton.vue'

const router = useRouter()
const { t } = useI18n()

const props = defineProps({
  // Broadcast Page
  resource: {
    type: Object,
    required: true,
  },
})

const broadcastPageExist = computed(() => (
  props.resource.relationships?.broadcast_page !== null
))

const hasPermissionToUpdate = computed(() => props.resource.relationships?.broadcast_page?.authorizations?.update)
const hasPermissionToCreate = computed(() => true)

const handleEditRoute = () => {
  router.push({
    name: 'update-broadcast-page',
    params: { id: props.resource.relationships?.broadcast_page?.id },
  })
}

const handleCreateRoute = () => {
  router.push({
    name: 'create-broadcast-page',
    params: { poi_id: props.resource.id },
  })
}
</script>
